'use client';
import React, { useLayoutEffect, useState, useEffect, Fragment } from 'react';
import { m, useMotionValue } from 'framer-motion';
import Image from 'next/image';
import IKLink from '@/components/shared/material/IKLink';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { useExperiment } from '@statsig/react-bindings';
interface MenuContainerProps {
  refs: any;
  menuItems: any[];
  hasScrolled: boolean;
  selectedNavOption: number | null;
  selectedNavOptionPosition: { x: number };
  onNavItemClick: () => void;
}

const MenuContainer = ({
  refs,
  menuItems,
  hasScrolled,
  selectedNavOption,
  onNavItemClick,
  selectedNavOptionPosition,
}: MenuContainerProps) => {
  const containerWidth = useMotionValue<number | null>(null);
  const containerHeight = useMotionValue<number | null>(null);

  const [isFirstInteraction, setIsFirstInteraction] = useState(true);
  const [cachedSelectedNavOption, setCachedSelectedNavOption] = useState<
    number | null
  >(null);
  const [lastArrowPosition, setLastArrowPosition] = useState<number | null>(
    null
  );
  const experimentName = 'ecom_vs_marketing_pages';
  const experiment = useExperiment(experimentName);
  const buttonText = experiment.get('CTA Button Text', 'Buy now');

  useEffect(() => {
    if (selectedNavOption !== null) {
      setCachedSelectedNavOption(selectedNavOption);
      if (selectedNavOptionPosition && selectedNavOptionPosition.x) {
        setLastArrowPosition(selectedNavOptionPosition.x);
      }
      setTimeout(() => {
        setIsFirstInteraction(false);
      }, 100);
    } else setIsFirstInteraction(true);
  }, [selectedNavOption, selectedNavOptionPosition]);

  useLayoutEffect(() => {
    if (selectedNavOption === null || !refs.current[selectedNavOption]) return;

    const selectedRef = refs.current[selectedNavOption];
    const width = selectedRef.current?.clientWidth ?? 0;
    const height = selectedRef.current?.clientHeight ?? 0;

    containerWidth.set(width);
    containerHeight.set(height);
  }, [selectedNavOption, containerWidth, containerHeight, refs]);

  const menuAnimation = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const getLink = (buttonText: string, text: string, defaultLink: any) => {
    if (buttonText === 'Buy now' && text === 'iK Flyer') {
      return {
        document: 'product',
        type: 'internal',
        category: 'card-machines',
        url: 'ik-flyer',
      };
    }
    if (buttonText === 'Buy now' && text === 'iK Flyer Lite') {
      return {
        document: 'product',
        type: 'internal',
        category: 'card-machines',
        url: 'ik-flyer-lite',
      };
    }
    if (buttonText === 'Buy now' && text === 'iK POS') {
      return {
        document: 'product',
        type: 'internal',
        category: 'point-of-sale',
        url: 'ik-pos',
      };
    }
    if (buttonText === 'Add to cart' && text === 'iK Flyer') {
      return {
        document: 'page',
        type: 'internal',
        url: 'ik-flyer',
      };
    }
    if (buttonText === 'Add to cart' && text === 'iK Flyer Lite') {
      return {
        document: 'page',
        type: 'internal',
        url: 'ik-flyer-lite',
      };
    }
    if (buttonText === 'Add to cart' && text === 'iK POS') {
      return {
        document: 'page',
        type: 'internal',
        url: 'ik-pos',
      };
    }
    return defaultLink;
  };

  return (
    <>
      <m.div
        className={`pointer-events-none absolute bottom-0 left-0 right-0 z-40 flex w-full justify-center ${
          hasScrolled ? 'top-[calc(70px_-_1px)]' : 'top-[calc(81px_-_1px)]'
        } `}
        transition={{ duration: 0.2 }}
        style={{
          originX: 0.5,
          originY: 0,
          transformPerspective: 1000,
          height: '100%',
        }}
        animate={selectedNavOption !== null ? 'visible' : 'hidden'}
        variants={menuAnimation}
      >
        <m.div
          transition={{
            duration: isFirstInteraction ? 0 : 0.2,
            type: 'spring',
            stiffness: 80,
            damping: 14,
          }}
          className={`origin-to-right z-10 h-full ${
            selectedNavOption !== null
              ? '[pointer-events:_fill]'
              : 'pointer-events-none'
          }`}
          style={{
            width: containerWidth,
            height: containerHeight,
            transition: isFirstInteraction ? 'none' : '0.2s',
          }}
        >
          {/* Arrow */}
          <m.div
            className={`absolute -mt-1.5 h-5 w-5 rotate-45 rounded-tl-[3px] bg-white shadow-sm ${
              selectedNavOption !== null
                ? 'opacity-100'
                : 'translate-x-5 opacity-0'
            }`}
            transition={{ duration: isFirstInteraction ? 0 : 0.2 }}
            style={{
              left:
                selectedNavOptionPosition && selectedNavOptionPosition.x
                  ? selectedNavOptionPosition.x - 8
                  : lastArrowPosition
                    ? lastArrowPosition - 8
                    : 'unset',
              top: isFirstInteraction ? '15px' : '-2px',
              transition: isFirstInteraction
                ? 'none'
                : 'left 0.2s, top 0.1s, transform 0.2s, opacity 0.2s',
            }} /* [8 -> half of arrow width] */
          />

          <div className='relative h-full w-full max-w-[1100px]'>
            <m.div
              className={`origin-to-right relative h-full w-full overflow-hidden rounded-[10px] bg-white bg-gradient-to-b from-white to-yellow-100/70 shadow-lg`}
              style={{
                transition: isFirstInteraction ? 'none' : 'all 0.2s',
                transform: `translateX(${
                  selectedNavOption === 3 || cachedSelectedNavOption === 3
                    ? '150px'
                    : selectedNavOption === 2 || cachedSelectedNavOption === 2
                      ? '60px'
                      : '2px'
                })`,
              }}
            >
              {menuItems.map((item, index) => (
                <Fragment key={item.layout + '-' + index}>
                  <m.div
                    className={`absolute left-0 top-0 ${
                      selectedNavOption === index ? '' : 'pointer-events-none'
                    } `}
                    transition={{ duration: isFirstInteraction ? 0 : 0.2 }}
                    animate={selectedNavOption === index ? 'visible' : 'hidden'}
                    variants={menuAnimation}
                  >
                    <section
                      className={`w-max px-1.5 pt-3 text-charcoal ${
                        item.layout === 'multi-column' ? 'flex' : 'pb-5'
                      }`}
                      ref={refs.current[index]}
                    >
                      {item.children &&
                        item.children.length > 0 &&
                        item.children.map((child: any, childIndex: number) => (
                          <Fragment key={index + '-' + childIndex}>
                            <ul className='px-2'>
                              <li
                                className={`flex h-full min-w-[310px] max-w-[310px] flex-col ${
                                  child.children && child.children.length > 0
                                    ? 'pb-5'
                                    : ''
                                } }`}
                              >
                                {child.type === 'heading' && (
                                  <span className='mb-3 block px-2 pt-2 text-xs font-medium uppercase tracking-[1.2px] text-charcoal-400'>
                                    {child.text}
                                  </span>
                                )}
                                {child.type === 'link' && (
                                  <IKLink
                                    // link={child.link}
                                    link={getLink(
                                      buttonText,
                                      child.text,
                                      child.link
                                    )}
                                    className='group flex flex-col gap-0.5 rounded-md p-2 hover:bg-yellow-150'
                                    onClick={() => onNavItemClick()}
                                  >
                                    <span className='flex items-center gap-2'>
                                      <span>{child.text}</span>
                                      {child.link.type === 'external' && (
                                        <span>
                                          <ArrowSquareOut
                                            weight='bold'
                                            className='mb-0.5 text-charcoal'
                                          />
                                        </span>
                                      )}
                                    </span>
                                    <span className='text-xs text-charcoal-400 transition-all duration-100 group-hover:text-charcoal-900'>
                                      {child.description}
                                    </span>
                                  </IKLink>
                                )}
                                {child.children &&
                                  child.children.length > 0 && (
                                    <ul
                                      className={`${
                                        childIndex < item.children.length - 1
                                          ? 'flex-grow border-r border-gray-200 pr-4'
                                          : ''
                                      }`}
                                    >
                                      {child.children.map(
                                        (
                                          secondLevelChild: any,
                                          secondLevelIndex: number
                                        ) => (
                                          <Fragment
                                            key={
                                              index +
                                              '-' +
                                              childIndex +
                                              '-' +
                                              secondLevelIndex
                                            }
                                          >
                                            <li className=''>
                                              {secondLevelChild.description && (
                                                <IKLink
                                                  className={`group flex rounded-md p-2 hover:bg-yellow-150 ${
                                                    secondLevelChild.image &&
                                                    secondLevelChild.image.url
                                                      ? 'gap-2'
                                                      : 'flex-col gap-0.5'
                                                  }`}
                                                  // link={secondLevelChild.link}
                                                  link={getLink(
                                                    buttonText,
                                                    secondLevelChild.text,
                                                    secondLevelChild.link
                                                  )}
                                                  onClick={() =>
                                                    onNavItemClick()
                                                  }
                                                >
                                                  {secondLevelChild.image &&
                                                  secondLevelChild.image.url ? (
                                                    <>
                                                      <span className='-my-1 -ml-1 block flex-shrink-0'>
                                                        <Image
                                                          width={50}
                                                          height={50}
                                                          src={
                                                            secondLevelChild
                                                              .image.url
                                                          }
                                                          alt={
                                                            secondLevelChild
                                                              .image.alt
                                                              ? secondLevelChild
                                                                  .image.alt
                                                              : (secondLevelChild.text ??
                                                                'iKhokha')
                                                          }
                                                          placeholder='blur'
                                                          blurDataURL={
                                                            secondLevelChild
                                                              .image.blurDataUrl
                                                          }
                                                          className={`rounded-md`}
                                                        />
                                                      </span>
                                                      <span className='flex flex-col gap-0.5'>
                                                        <span className='flex items-center gap-2'>
                                                          <span>
                                                            {
                                                              secondLevelChild.text
                                                            }
                                                          </span>
                                                          {secondLevelChild.link
                                                            .external && (
                                                            <span>
                                                              <ArrowSquareOut className='mb-0.5 text-charcoal' />
                                                            </span>
                                                          )}
                                                        </span>
                                                        <span className='text-xs text-charcoal-400 transition-all duration-100 group-hover:text-charcoal-900'>
                                                          {
                                                            secondLevelChild.description
                                                          }
                                                        </span>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <span className='flex items-center gap-2'>
                                                        <span>
                                                          {
                                                            secondLevelChild.text
                                                          }
                                                        </span>
                                                        {secondLevelChild.link
                                                          .type ===
                                                          'external' && (
                                                          <span>
                                                            <ArrowSquareOut
                                                              weight='bold'
                                                              className='mb-0.5 text-charcoal'
                                                            />
                                                          </span>
                                                        )}
                                                      </span>
                                                      <span className='text-xs text-charcoal-400 transition-all duration-100 group-hover:text-charcoal-900'>
                                                        {
                                                          secondLevelChild.description
                                                        }
                                                      </span>
                                                    </>
                                                  )}
                                                </IKLink>
                                              )}
                                              {!secondLevelChild.description && (
                                                <IKLink
                                                  className='group flex items-center gap-2 rounded-md p-2 hover:bg-yellow-150'
                                                  link={secondLevelChild.link}
                                                  onClick={() =>
                                                    onNavItemClick()
                                                  }
                                                >
                                                  <span className='flex items-center gap-2'>
                                                    <span>
                                                      {secondLevelChild.text}
                                                    </span>
                                                    {secondLevelChild.link
                                                      .type === 'external' && (
                                                      <span>
                                                        <ArrowSquareOut
                                                          weight='bold'
                                                          className='mb-0.5 text-charcoal'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>
                                                  <span className='inline-block transition-transform duration-200 group-hover:translate-x-1'>
                                                    <svg
                                                      className='h-3 w-3'
                                                      xmlns='http://www.w3.org/2000/svg'
                                                      width='15'
                                                      height='14'
                                                      viewBox='0 0 15 14'
                                                      fill='none'
                                                    >
                                                      <path
                                                        d='M9.02541 13.6935L14.6305 8.09371C14.7218 8.01069 14.7948 7.90977 14.8447 7.79734C14.8945 7.68492 14.9203 7.56344 14.9203 7.44063C14.9203 7.31781 14.8945 7.19634 14.8447 7.08391C14.7948 6.97148 14.7218 6.87056 14.6305 6.78755L9.02541 1.18772C8.85867 1.02596 8.63959 0.927829 8.40688 0.910664C8.17418 0.8935 7.94284 0.958411 7.75378 1.09392C7.64016 1.16936 7.54552 1.26961 7.47712 1.38696C7.40873 1.50431 7.36842 1.63564 7.35927 1.77085C7.35013 1.90606 7.37241 2.04155 7.42439 2.16689C7.47637 2.29224 7.55667 2.4041 7.65911 2.49388L11.71 6.50616L1.72625 6.50616C1.4828 6.5155 1.25247 6.6179 1.08356 6.79186C0.914661 6.96582 0.820313 7.19783 0.820313 7.43923C0.820313 7.68062 0.914661 7.91263 1.08356 8.0866C1.25247 8.26056 1.4828 8.36295 1.72625 8.3723L11.7114 8.3723L7.66052 12.386C7.55808 12.4758 7.47778 12.5876 7.4258 12.713C7.37382 12.8383 7.35154 12.9738 7.36069 13.109C7.36983 13.2442 7.41015 13.3755 7.47854 13.4929C7.54693 13.6102 7.64157 13.7105 7.75519 13.7859C7.94426 13.9214 8.17559 13.9864 8.4083 13.9692C8.641 13.952 8.86009 13.8539 9.02682 13.6921L9.02541 13.6935Z'
                                                        fill='currentColor'
                                                      />
                                                    </svg>
                                                  </span>
                                                </IKLink>
                                              )}
                                            </li>
                                          </Fragment>
                                        )
                                      )}
                                    </ul>
                                  )}
                              </li>
                            </ul>
                          </Fragment>
                        ))}
                    </section>
                  </m.div>
                </Fragment>
              ))}
            </m.div>
          </div>
        </m.div>
      </m.div>
    </>
  );
};

export default MenuContainer;
